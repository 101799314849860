
@use '@angular/material' as mat;
@include mat.core();

$pavso-primary-light: (
  50 : #e5ecff,
  100 : #bdcfff,
  200 : #91b0ff,
  300 : #6590ff,
  400 : #4478ff,
  500 : #0368c1,
  600 : #1f58ff,
  700 : #1a4eff,
  800 : #1544ff,
  900 : #0c33ff,
  A100 : #ffffff,
  A200 : #f6f7ff,
  A400 : #c3caff,
  A700 : #a9b4ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$afsdf: (
  50 : #e1e3ec,
  100 : #b3b9d0,
  200 : #818bb1,
  300 : #4f5c92,
  400 : #29397a,
  500 : #031663,
  600 : #03135b,
  700 : #021051,
  800 : #020c47,
  900 : #010635,
  A100 : #6d71ff,
  A200 : #3a40ff,
  A400 : #070eff,
  A700 : #0007ec,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);
$assa :(
  50 : #e3eaef,
  100 : #b9cad8,
  200 : #8ba7be,
  300 : #5c83a4,
  400 : #396991,
  500 : #164e7d,
  600 : #134775,
  700 : #103d6a,
  800 : #0c3560,
  900 : #06254d,
  A100 : #81afff,
  A200 : #4e8fff,
  A400 : #1b6eff,
  A700 : #025eff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);


/* For use in src/lib/core/theming/_palette.scss */
$pavso-accent-light: (
  50 : #e0f8f3,
  100 : #b3ede1,
  200 : #80e1cd,
  300 : #4dd4b9,
  400 : #26cbaa,
  500 : #00c29b,
  600 : #00bc93,
  700 : #00b489,
  800 : #00ac7f,
  900 : #009f6d,
  A100 : #c9ffec,
  A200 : #96ffd9,
  A400 : #63ffc7,
  A700 : #4affbe,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$pavso-primary-dark: (
    50 : #e1e3e5,
    100 : #b5babe,
    200 : #838c93,
    300 : #515d67,
    400 : #2c3b47,
    500 : #071826,
    600 : #061522,
    700 : #05111c,
    800 : #040e17,
    900 : #02080d,
    A100 : #5196ff,
    A200 : #1e78ff,
    A400 : #005dea,
    A700 : #0053d0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// $my-app-primary: mat-palette($mat-blue-grey);
// $my-app-accent:  mat-palette($mat-pink, 500, 900, A100);
// $my-app-warn:    mat-palette($mat-deep-orange);


$my-app-primary: mat.define-palette($pavso-primary-light);
$my-app-accent:  mat.define-palette($pavso-accent-light, 500, 900, A100);
$my-app-warn:    mat.define-palette(mat.$deep-orange-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-component-themes($my-app-theme);


.alternate-theme {
  $alternate-primary: mat.define-palette($pavso-primary-dark);
  $alternate-accent:  mat.define-palette($pavso-accent-light, 500, 900, A100);
  $alternate-theme: mat.define-dark-theme($alternate-primary, $alternate-accent);

  @include mat.all-component-themes($alternate-theme);
}

// $auth-primary: mat-palette($mat-indigo, 50, 100);
// $auth-accent: mat-palette($mat-indigo, 50, 100);
// $auth-warn: mat-palette($mat-pink, 400, 500);
// $auth-theme: mat-dark-theme($auth-primary, $auth-accent, $auth-warn);

// @include mat-form-field-theme($auth-theme);
// @include mat-input-theme($auth-theme);
// @include mat-card-theme($auth-theme);
