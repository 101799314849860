
@import "~animate.css/animate.min.css";

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(./assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"),
    url(./assets/fonts/Nunito/Nunito-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Medium";
  src: local("Nunito-Medium"),
    url(./assets/fonts/Nunito/Nunito-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"),
    url(./assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito-SemiBold"),
    url(./assets/fonts/Nunito/Nunito-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Figtree";
  src: local("Figtree"),
    url(./assets/fonts/Figtree/static/Figtree-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Figtree-Bold";
  src: local("Figtree-Bold"),
    url(./assets/fonts/Figtree/static/Figtree-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Figtree-Medium";
  src: local("Figtree-Medium"),
    url(./assets/fonts/Figtree/static/Figtree-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Figtree-Regular";
  src: local("Figtree-Regular"),
    url(./assets/fonts/Figtree/static/Figtree-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Figtree-SemiBold";
  src: local("Figtree-SemiBold"),
    url(./assets/fonts/Figtree/static/Figtree-SemiBold.ttf) format("truetype");
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

body {
  --text-color: #121d44;
  --bkg-color: #fff;
  --btn-txt-color: white;
  --btn-bg-color: #0368c1;

  --msc-bg-color: #eaf0f9;
  --mat-toolbar: #fff;
  --mat-dialog-bg: #fff;
  --mat-dialog-bg-option-1: #fff;

  --pav-search-container: #fff;
  --pav-card-more-options: #4493ff;
  --pav-table-bg: #fff;
  --pav-table-thead: #f3f6fa;
  --pav-table-td-color: #00205f;
  --pav-table-td-even: #e0feff;

  --pav-icon-svg: #121d44;
  --pav-container-button: #f2f2f2;
  --pav-container-button-hover: #e0e0e0;
  --pav-button-action: #0368c1;
  --pav-button-cancel: #f2f2f2;
  --pav-bottom-actions: rgb(255, 255, 255);
  --pav-btn-action: #f2f2f2;
  --pav-chart-header-bg: #4493ff;
  --pav-table-header-container: #4493ff;
  --pav-scroll-menu-item: #121d44;
  --pav-filter-header: #4493ff;
  --pav-filter-container: #fff;
  --mat-menu-bg: #fff;
  --menu-title-color: #00205f;
  --pav-notification-row-hover: #181a1b;
  --mat-table-row-hover: #e9f3ff;

  --profile-picture-bg: #ace7ee;
  --profile-picture-bg-hover: #86dae4;

  --pav-notifications-bg: #fff;
  --list-item-hover: #f2f2f2;
  --pav-tab-header: #ffffff;
}

body.dark-theme {
  --text-color: rgb(205, 200, 194);
  --bkg-color: #252222;
  --btn-txt-color: rgb(46, 43, 43);
  --btn-bg-color: rgb(20, 211, 195);

  --msc-bg-color: #202224;
  --mat-toolbar: rgb(24, 26, 27);
  --mat-dialog-bg: #181a1b;
  --mat-dialog-bg-option-1: #3e4446;
  --pav-search-container: #181a1b;
  --pav-card-more-options: #003782;
  --pav-table-bg: #181a1b;
  --pav-table-thead: #1d1f20;
  --pav-table-td-color: #aea9a5;
  --pav-table-td-even: #181a1b;

  --pav-icon-svg: #fff;
  --pav-container-button: rgb(31, 34, 35);
  --pav-container-button-hover: #424242;
  --pav-btn-action: #424242;
  --pav-button-action: rgb(0, 51, 183);
  --pav-button-cancel: rgb(31, 34, 35);
  --pav-bottom-actions: rgb(24, 26, 27);
  --pav-chart-header-bg: rgb(0, 69, 163);
  --pav-table-header-container: rgb(0, 69, 163);
  --pav-scroll-menu-item: rgb(205, 200, 194);
  --pav-filter-header: rgb(0, 69, 163);
  --pav-filter-container: #181a1b;
  --mat-menu-bg: #181a1b;
  --menu-title-color: #eee;
  --pav-notification-row-hover: #424242;
  --mat-table-row-hover: #424242;

  --profile-picture-bg: #0368c1;
  --profile-picture-bg-hover: #4493ff;

  --pav-notifications-bg: #424242;
  --list-item-hover: #202224;
  --pav-tab-header: #424242;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* ::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

.mat-card {
  border-radius: 15px !important;
}

mat-form-field {
  width: 100%;
}

.yellow {
  background: #f5c643;
}

.mat-sort-header-content {
  color: var(--text-color) !important;
}

.blue {
  background: #4493ff;
}

.blue-dark {
  background: #00205f;
}

.red {
  background: #eb6945;
}

.green {
  background: #63c99a;
}

.pav-card-header {
  display: flex;
  height: 55px;
  border-radius: 15px 15px 0px 0px;
  align-items: center;
}

.pav-card-header .pav-block {
  height: 15px;
  width: 7px;
  background: #ffffff;
  margin-left: 30px;
}

.pav-card-header .pav-card-header-title {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-left: 20px;
}

.pavso-tooltip-color {
  background: #00205f !important;
}

.pav-button-add-item {
  background: var(--pav-button-action) !important;
  color: white !important;
  font-family: "Figtree-Bold" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  width: 180px !important;
  height: 48px !important;
  border-radius: 30px !important;
}

.pav-button-add-item:hover {
  background: #0848ed;
  color: white;
}

.mat-edit {
  color: #fff !important;
  background-color: #ffa727 !important;
}

.mat-edit[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-editar {
  color: #ffa727 !important;
}

.mat-editar[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-excel {
  color: #fff !important;
  background-color: #149f5c !important;
}

.mat-excel[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.container {
  padding: 10px;
}

.content-spinner {
  text-align: center;
  text-align: -webkit-center;
}

.center {
  text-align: center;
  text-align: -webkit-center;
}

.color-gray {
  background-color: #fafafa;
}

.mat-cell {
  padding: 0px 5px !important;
}

.mat-header-cell {
  padding: 0px 5px !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 16px !important;
}

.mat-bottom-sheet-container-large {
  max-width: 200px !important;
}

.pavso-user-date {
  display: inline;
  float: right;
  font-weight: bold;
  padding-top: 10px;
}

.spacer {
  flex: 1 1 auto;
}

.pavso-tab-container {
  padding: 15px 0px;
}

/* .mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:focus,
.mat-list-option:focus {
  background: #f5f5f5 !important;
} */

.pavso_more_options {
  float: right;
  margin-top: -10px !important;
}

.pavso_card_more_options {
  display: flex;
  padding: 10px 25px;
  background: var(--pav-card-more-options);
  align-items: center;
  justify-content: center;
  border-radius: 15px 15px 0px 0px;
  margin-top: 19px;
}

.pav-table-header-icon {
  width: 7px;
  height: 15px;
  background-color: white;
  margin-right: 15px;
}

.pav-title-dialog {
  font-size: 18px !important;
  font-family: "Figtree-Bold" !important;
  color: var(--text-color);
}

.pav-table-header-title {
  flex: 1 1 auto;
  color: #ffffff;
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.pavso_title {
  /* color: #546e7a; */
  font-size: 12px;
}

.pavso_subtitle {
  margin-top: 10px;
}

table {
  width: 100%;
}

.pavso_btn_add {
  float: right;
  margin-top: 25px !important;
}

.pavso_btn_excel {
  float: right;
  margin-top: 10px;
}

.btn-action {
  margin-bottom: 20px;
}

.activo {
  color: #4caf50 !important;
  background-color: rgba(76, 175, 80, 0.08) !important;
}

.inactivo {
  color: #f44336 !important;
  background-color: rgba(244, 67, 54, 0.08) !important;
}

.cliente {
  color: #428fce !important;
  background-color: rgba(76, 122, 175, 0.08) !important;
}

.usuario {
  color: #e69e18 !important;
  background-color: rgba(244, 168, 54, 0.08) !important;
}

.mff-services {
  width: auto;
}

.soundwave {
  animation: soundwave 0.7s infinite;
}

@keyframes soundwave {
  from {
    opacity: 0.5;
    color: #ffffff;
  }

  20% {
    color: #ffffff;
    opacity: 0.6;
  }

  35% {
    color: #ffffff;
    opacity: 0.9;
  }

  45% {
    color: #ffffff;
    opacity: 1;
  }

  55% {
    color: #ffffff;
    opacity: 0.9;
  }

  to {
    color: #ffffff;
    opacity: 0.5;
  }
}

table {
  width: 100%;
  border-radius: 0px 0px 15px 15px;
}

td.mat-column-star {
  width: 20px;
  padding-right: 8px !important;
}

.mat-row:hover {
  background-color: var(--mat-table-row-hover);
}

.mat-row a {
  color: #3e75ff;
  text-decoration: none;
}

.mat-row a:hover {
  text-decoration: underline;
}

.activation-content {
  display: flex;
  float: left;
}

.notification {
  margin-left: 20px;
  margin-right: 20px;
}

.pavso-select-service {
  width: auto;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
}

.table {
  overflow: scroll;
}

.mat-header-row {
  /* position: sticky; */
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  background-color: inherit;
}

.select_type_user {
  width: auto;
  float: right;
  margin-right: 15px;
}

mat-checkbox ::ng-deep .mat-checkbox-label {
  font-size: 12px;
}

td {
  text-align: left;
  padding-top: 15px !important;
  font-size: 10px !important;
}

.pavso-container-action {
  width: 120px;
}

.pavso-container-description {
  width: 250px;
}

.float-right {
  float: right;
}

.mat-paginator {
  background: #fff;
}

.pavso-filtro {
  position: absolute;
  bottom: 0;
}

.pavso-bottom-actions {
  background: var(--pav-bottom-actions) !important;
  position: fixed !important;
  top: 100px;
  left: 0;
  right: 0;
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* width: calc(100% - 304px); */
  z-index: 2;
  /* margin-left: -48px; */
  border-radius: 0px !important;
}

.pav-amount {
  margin-top: 30px;
  font-size: 20px;
}

.pavso-container {
  padding: 30px 48px !important;
}

.pavso-content-form {
  padding-bottom: 0px;
}

.pavso_content {
  padding: 0px;
}

.mat-cell {
  font-size: 12px;
  min-height: 48px;
}

.mat-row:nth-child(even) {
  background-color: var(--pav-table-td-even);
  border-radius: 15px;
}

/* .mat-row:nth-child(odd) {

} */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #164e7d;
  animation: spinner 0.8s linear infinite;
}

.mat-snack-bar-container {
  border-radius: 8px !important;
}

.green-snackbar {
  background: #d2f5df !important;
  color: white !important;
}

.green-snackbar button {
  background: #d2f5df !important;
  color: #4f4f4f !important;
}

.alert-snackbar {
  background: #eedfb5 !important;
  color: #4f4f4f !important;
}

.alert-snackbar .mat-simple-snack-bar-content:before {
  content: "\26A0 ";
  background-image: url("assets/svg/atencion.svg");
}

.alert-snackbar button {
  background: #eedfb5 !important;
  color: #4f4f4f !important;
}

mat-sidenav-content {
  background: var(--msc-bg-color);
}

.mat-sidenav-content {
  height: 100% !important;
}

mat-form-field.mat-form-field {
  font-size: 12px;
  font-family: Figtree;
}

/* ESTILOS PERSONALIZADOS BOTONTES */
.pav-button-flex {
  height: 46px;
  width: 100%;
  border-radius: 52px !important;
  background: var(--pav-button-action) !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  margin-bottom: 15px !important;
}

.pav-button-flex:hover {
  background: #0848ed !important;
}

.pav-button-action {
  width: 250px;
  height: 46px;
  border-radius: 52px !important;
  background: var(--pav-button-action) !important;
  color: white !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
}

.pav-button-action:hover {
  background: #0848ed !important;
}

.pav-button-other {
  width: 100%;
  height: 46px;
  border-radius: 52px !important;
  background: #63c99a !important;
  color: white !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
}

.pav-button-other:hover {
  background: #3aa272 !important;
}

.pav-button-load {
  width: 200px;
  height: 46px;
  border-radius: 52px !important;
  background: #63c99a !important;
  color: white !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.pav-button-load:hover {
  background: #3aa272 !important;
}

.pav-button-cancel {
  width: 250px;
  height: 46px;
  border-radius: 52px !important;
  background: var(--pav-button-cancel) !important;
  color: var(--text-color) !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
}

.pav-button-cancel:hover {
  background: var(--pav-button-cancel) !important;
}

.pav-button-download-excel {
  width: 180px;
  height: 48px;
  border-radius: 52px !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 30px;
  color: white;
  font-family: "Figtree-Bold" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 19px !important;
}

.pav-button-add {
  width: 180px;
  height: 48px;
  border-radius: 52px !important;
  background: #0368c1 !important;
  font-family: "Figtree-Bold" !important;
  font-style: normal !important;
  font-weight: 600 v;
  font-size: 0.875rem !important;
  line-height: 19px !important;
}

.pav-button-add:hover {
  background: #0848ed !important;
}

.pav-button-icon-download-excel {
  width: 40px;
  height: 40px;
  background: rgb(99, 201, 154);
  /* opacity: 0.2; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.pav-button-icon-add-modal {
  width: 48px;
  height: 48px;
  background: rgb(235 148 80);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pav-button-icon-add-multiple {
  width: 48px;
  height: 48px;
  background: rgb(80 223 235);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pav-button-icon-add {
  width: 48px;
  height: 48px;
  background: rgb(247, 201, 27);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pav-button-icon-add-form {
  width: 40px;
  height: 40px;
  background: #0368c1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pav-button-icon-delete {
  width: 48px;
  height: 48px;
  background: #eb6945;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pav-button-icon-insert {
  width: 48px;
  height: 48px;
  background: #0368c1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pav-button-icon-whatsapp {
  width: 200px;
  height: 46px;
  border-radius: 52px !important;
  background: #25d366 !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 v;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  color: white !important;
}

@media screen and (max-width: 960px) {
  .pav-button-icon-whatsapp {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
  }
}

.pav-button-icon-mail {
  width: 190px;
  height: 46px;
  border-radius: 52px !important;
  background: #eb6945 !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 v;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  color: white !important;
}

@media screen and (max-width: 960px) {
  .pav-button-icon-mail {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
  }
}

.pav-button-ok {
  width: 333px;
  height: 46px;
  border-radius: 52px !important;
  background: #0368c1 !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 v;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  color: white !important;
}

.pav-button-ok:hover {
  background: #0848ed !important;
}

.pav-button-custom {
  width: auto;
  height: 35px;
  border-radius: 52px !important;
  background-color: var(--btn-bg-color) !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: bold;
  font-size: 0.875rem !important;
  line-height: 19px !important;
  color: white !important;
}

.mat-dialog-container {
  border-radius: 15px !important;
}

.mat-toolbar {
  background: var(--mat-toolbar) !important;
  height: 100px;
}

.mat-toolbar {
  justify-content: center;
}

.mat-toolbar-row {
  justify-content: center;
}

.pav-container-button {
  background: var(--pav-container-button);
  border-radius: 8px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.pav-container-button:hover {
  background: var(--pav-container-button-hover);
}

.mat-list-base {
  padding-top: 0px !important;
}

.pav-filter-search {
  display: flex;
  background: #ffffff;
  border-radius: 15px;
  padding: 25px 20px 10px 20px;
}

.pav-filter-search-control {
  margin-right: 15px;
}

.mat-form-field-wrapper {
  margin: 0px !important;
  padding-top: 0px !important;
}

.row .col {
  padding: 0 7px !important;
}

.pav-separador {
  width: 15px;
}

table /deep/ .mat-sort-header-content {
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--pav-table-td-color) !important;
}

.pav-table-content {
  padding: 20px;
  border-radius: 0px 0px 15px 15px;
  background: white;
}

.mat-paginator {
  background: transparent !important;
}

tr.mat-row {
  height: 50px !important;
}

td.mat-cell {
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

th.mat-header-cell {
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

td.mat-cell {
  color: var(--pav-table-td-color) !important;
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 16px !important;
}

th.mat-header-cell {
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  line-height: 16px !important;

  /* fondo */

  color: black !important;
}

td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

table th:first-child {
  border-radius: 10px 0 0 10px;
}

table th:last-child {
  border-radius: 0 10px 10px 0;
}

tr.mat-header-row {
  height: 30px !important;
}

thead {
  background: var(--pav-table-thead) !important;
  color: var();
}

table {
  padding: 22px 28px !important;
}

.pav-button-icon {
  background: #0368c1 !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.mat-menu-panel {
  border-radius: 15px !important;
  min-height: 53px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

mat-nav-list /deep/ .module {
  border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.b-r-15 {
  border-radius: 15px !important;
}

.pav-table-header-container {
  height: 77px;
  display: flex;
  background-color: var(--pav-table-header-container);
  border-radius: 15px 15px 0px 0px;
  align-items: center;
  padding: 0px 30px;
}

.pav-table-header-title {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.pav-input-search {
  margin-right: 15px;
}

.input-search {
  height: 48px;
  width: 250px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  padding-left: 30px;
  color: #ffffff;
}

.input-search:focus {
  outline: none !important;
  height: 48px;
  width: 250px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  padding-left: 30px;
  color: #ffffff;
}

.input-search::placeholder {
  color: #ffffff;
}

.mat-drawer-side {
  border: none !important;
}

.pav-table-search-container {
  display: flex;
  background: var(--pav-search-container);
  border-radius: 15px;
  padding: 25px;
}

.pav-table-search input {
  height: 48px;
  width: auto;
  border-radius: 30px;
  background: #f3f6fa;
  border: none;
  padding-left: 25px;
  color: #121d44;
  opacity: 0.5;
  padding-right: 50px;
  background-image: url(assets/svg/search.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  background-position-x: 185px;
}

.pav-table-search input:focus {
  outline: none !important;
  height: 48px;
  width: auto;
  border-radius: 30px;
  background: #f3f6fa;
  border: none;
  padding-left: 25px;
  color: #121d44;
  opacity: 0.5;
  padding-right: 50px;
  background-image: url(assets/svg/search.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  background-position-x: 185px;
}

.pav-table-search input::placeholder {
  color: #121d44;
}

.pav-separator {
  flex: 1 1 auto;
}

.pav-btns-container {
  display: flex;
}

.pav-btn-circle-edit {
  border-radius: 50%;
  background: #0368c1;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 7px;
}

.pav-btn-circle-edit:hover {
  background: #0848ed;
}

.pav-btn-circle-edit mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-active {
  border-radius: 50%;
  background: #63c99a;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-active:hover {
  background: #407e61;
}

.pav-btn-circle-active mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-whatsapp {
  border-radius: 50%;
  background: #25d366;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-whatsapp:hover {
  background: #3c7e54;
}

.pav-btn-circle-whatsapp mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-pdf {
  border-radius: 50%;
  background: #eb6945;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-pdf:hover {
  background: #eb6945;
}

.pav-btn-circle-pdf mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-factura {
  border-radius: 50%;
  background: rgb(99, 201, 154);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-factura:hover {
  background: rgb(99, 201, 154);
}

.pav-btn-circle-factura mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-guia {
  border-radius: 50%;
  background: rgb(247, 201, 27);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-guia:hover {
  background: rgb(247, 201, 27);
}

.pav-btn-circle-guia mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-follows {
  border-radius: 50%;
  background: #eb6945;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-follows:hover {
  background: #eb6945;
}

.pav-btn-circle-follows mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-confirm {
  border-radius: 50%;
  background: #023395;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.pav-btn-circle-confirm:hover {
  background: #042464;
}

.pav-btn-circle-confirm mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-delete {
  border-radius: 50%;
  background: #eb5757;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 7px;
}

.pav-btn-circle-delete mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-btn-circle-more {
  border-radius: 50%;
  background: #023395;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pav-btn-circle-more mat-icon {
  width: 17px !important;
  height: 0.875rem !important;
}

.pav-filtro-header {
  display: flex;
  background: var(--pav-filter-header);
  padding: 20px;
  border-radius: 15px 15px 0px 0px;
  align-items: center;
}

.pav-filtro-header .pav-filtro-header-icon {
  width: 7px;
  height: 15px;
  background: #ffffff;
  border-radius: 1px;
  margin-right: 15px;
}

.pav-filtro-header .pav-filtro-header-title {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.pav-filtro-container {
  border-radius: 15px;
  background: var(--pav-filter-container);
  border-radius: 0px 0px 15px 15px;
  padding: 20px 30px;
}

.pav-report-container {
  display: flex;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  border-radius: 10px;
  margin-left: 10px;
  border: 1px solid var(--pav-scroll-menu-item);
  color: var(--pav-scroll-menu-item);
  opacity: 0.4;
  font-size: 0.875rem;
  cursor: pointer;

  display: inline-block;
  text-align: center;
  padding: 0.875rem;
  text-decoration: none;
  width: 200px;
  border-style: dashed;
}

div.scrollmenu a > p {
  font-family: "Figtree";
  font-style: normal;
  white-space: pre-wrap;
  line-height: 1.5rem;
  height: 3.5em;
  overflow: hidden;
  margin: 0px;
}

div.scrollmenu a:hover {
  background-color: #63c99a;
  color: #fff;
  opacity: 0.7;
  font-size: 0.875rem;
  cursor: pointer;
}

.pav-report-item {
  background-color: #63c99a !important;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 0.875rem !important;
  cursor: pointer !important;

  /* border-radius: 10px;
  margin-left: 10px;
  border: 1px solid #121D44;
  color: #121D44;
  opacity: 0.4;
  font-size: 0.875rem;
  cursor: pointer;

  display: inline-block;
  text-align: center;
  padding: 0.875rem;
  text-decoration: none; */

  /* font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 19px;
  color: #FFFFFF;
  background: #023395;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  padding: 15px 20px;
  margin-right: 15px; */
}

.pav-report-item-selected {
  background-color: #023395;
  color: #fff;
  opacity: 1;
  font-size: 0.875rem;
  cursor: pointer;

  /* font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 19px;
  color: #121D44;
  opacity: 0.4;

  border: 1px solid #121D44;
  border-radius: 10px;

  cursor: pointer;
  display: flex;
  padding: 15px 20px;
  margin-right: 15px; */
}

.pav-chart-container {
  height: 55px;
  display: flex;
  align-items: center;
  background: var(--pav-chart-header-bg);
  border-radius: 15px 15px 0px 0px;
}

.pav-chart-container .pav-chart-header-icon {
  height: 15px;
  width: 7px;
  background: #ffffff;
  margin-left: 30px;
}

.pav-chart-container .pav-chart-header-text {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 25px;
  color: #ffffff;
  margin-left: 20px;
}

.pav-chart-container .pav-chart-header-more {
  cursor: pointer;
  margin-right: 30px;
}

.pav-chart-content {
  background: #ffffff;
  padding: 30px 20px;
  border-radius: 0px 0px 15px 15px;
}

.isMobile {
  display: none !important;
}

.isWeb {
  display: block !important;
}

@media screen and (max-width: 960px) {
  .isMobile {
    display: block !important;
  }

  .isWeb {
    display: none !important;
  }

  .pav-table-header-title {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 25px;
    color: #ffffff;
  }

  .pav-btns-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .pavso-container {
    padding: 15px 5px !important;
  }

  .pavso-field-user-date {
    font-size: 10px;
    color: 546e7a;
  }

  .pavso_subtitle {
    margin-top: 10px;
    font-size: 0.875rem;
  }

  .td-responsive {
    width: 70vw;
    width: auto;
    padding-left: 15px !important;
  }

  /* .pavso-bottom-actions {
    background: var(--pav-bottom-actions) !important;
    position: fixed !important;
    bottom: 0;
    width: 100%;
    z-index: 100;
  } */

  .mat-elevation-z8 {
    background: transparent;
    box-shadow: none;
  }

  .activation-content {
    display: flex;
    float: right;
  }

  .pav-button-icon-insert {
    width: 28px !important;
    height: 28px !important;
  }

  .pav-button-icon-insert > mat-icon {
    height: 18px !important;
    width: 26px !important;
  }

  .pav-button-icon-delete {
    width: 28px !important;
    height: 28px !important;
  }

  .pav-button-icon-delete > mat-icon {
    height: 18px !important;
    width: 26px !important;
  }

  .pav-button-icon-add-modal {
    width: 28px !important;
    height: 28px !important;
  }

  .pav-button-icon-add-modal > mat-icon {
    height: 18px !important;
    width: 26px !important;
  }

  .pav-button-icon-add-multiple > mat-icon {
    height: 18px !important;
    width: 26px !important;
  }

  .pav-button-icon-add-multiple {
    width: 28px !important;
    height: 28px !important;
  }

  .pav-button-icon-add {
    width: 28px !important;
    height: 28px !important;
  }

  .pav-button-icon-add > mat-icon {
    height: 18px !important;
    width: 26px !important;
  }

  mat-chip-list ::ng-deep .mat-chip-list-wrapper {
    display: block !important;
  }

  .slide-comp {
    margin-top: 5px;
    margin-left: 5px;
  }

  .pav-button-action {
    width: 100%;
    height: 46px;
    border-radius: 52px !important;
    background: #0368c1;
    font-family: "Figtree" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 19px !important;
    /* margin-right: 15px !important; */
    /* margin-left: 15px !important; */
  }

  .pav-button-action:hover {
    background: #0848ed !important;
  }

  .pav-button-load {
    width: 90% !important;
    height: 46px;
    border-radius: 52px !important;
    background: #63c99a !important;
    color: white !important;
    font-family: "Figtree" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 19px !important;
    margin-right: 15px !important;
    margin-left: 15px !important;
    margin-bottom: 10px !important;
  }

  .pav-button-load:hover {
    background: #3aa272 !important;
  }

  .pav-button-cancel {
    width: 100%;
    height: 48px;
    border-radius: 52px !important;
    background: var(--pav-button-cancel) !important;
    font-family: "Figtree" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 19px !important;
    /* margin-bottom: 16px !important;
    margin-right: 15px !important;
    margin-left: 15px !important; */
  }

  .pav-button-cancel:hover {
    background: #e0e0e0 !important;
  }

  .pav-table-search-container {
    display: block;
  }

  .pav-table-search input {
    height: 48px;
    width: 75%;
    border-radius: 30px;
    background: #f3f6fa;
    border: none;
    padding-left: 25px;
    color: #121d44;
    opacity: 0.5;
    padding-right: 50px;
    background-image: url(assets/svg/search.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px;
    background-position-x: 255px;
  }

  .pav-table-search input:focus {
    outline: none !important;
    height: 48px;
    width: 75%;
    border-radius: 30px;
    background: #f3f6fa;
    border: none;
    padding-left: 25px;
    color: #121d44;
    opacity: 0.5;
    padding-right: 50px;
    background-image: url(assets/svg/search.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px;
    background-position-x: 255px;
  }

  .pav-table-search input::placeholder {
    color: #121d44;
  }
}

/* End de CSS RESPONSIVO */

.pav-dialog-detail-liquid mat-dialog-container {
  background: #eaf0f9;
}

.pav-dialog-presupuesto mat-dialog-container {
  background: #eaf0f9;
}

.mat-step-header {
  border-radius: 15px;
}

.fade-in-image {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pav-not-connection {
  text-align: center;
  background-color: #eaf0f9;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.pav-img-not-connection {
  width: 20rem;
}

/* DIALOGS */
.pav-tutorial-container > mat-dialog-container {
  background-color: var(--mat-dialog-bg);
  padding: 0px;
}

.pav-dialog-container > mat-dialog-container {
  background-color: var(--mat-dialog-bg);
}

.pav-dialog-container-option-1 > mat-dialog-container {
  background-color: var(--mat-dialog-bg-option-1);
}

.full-screen-dialog {
  max-width: 100vw !important;
  width: 100vw !important;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background: #222;
}

.full-screen-dialog > mat-dialog-container {
  padding: 0px !important;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

cdk-overlay-pane full-screen-dialog

/* TABLE */
.alternate-theme .mat-table {
  background: var(--pav-table-bg) !important;
}

/* CHECKBOX  */
mat-checkbox {
  font-family: "Figtree" !important;
}

mat-checkbox ::ng-deep .mat-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
}

.pav-input-align-right {
  text-align: right !important;
}

/* APEXCHARTS STYLES */
apx-chart /deep/ .apexcharts-legend-text {
  color: white;
}

/* TABLE */
.pav-td-right {
  text-align: right;
  padding-right: 15px !important;
}

.pav-td-center {
  text-align: center !important;
}

.pav-th-right {
  text-align: right;
  padding-right: 15px !important;
}

.pav-th-center {
  text-align: center !important;
}

.pav-mat-form-margin-top {
  margin-top: 15px !important;
}

/* TOOLTIP */
/* [mattooltip] {
  touch-action: cross-slide-y !important;
} */
[matripple] {
  touch-action: auto !important;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(74, 74, 74, 0.8);
  z-index: 99999;
}

.spinner-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pav-tag-green {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(16, 185, 129, 0.12);
  border-radius: 12px;
  color: rgb(11, 129, 90);
  cursor: default;
  display: inline-flex;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.75rem;
  line-height: 2;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.5px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.pav-tag-blue {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(6, 174, 212, 0.12);
  border-radius: 12px;
  color: rgb(14, 112, 144);
  cursor: default;
  display: inline-flex;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.75rem;
  line-height: 2;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.5px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.pav-tag-red {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(240, 68, 56, 0.12);
  border-radius: 12px;
  color: rgb(180, 35, 24);
  cursor: default;
  display: inline-flex;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.75rem;
  line-height: 2;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.5px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #00205f !important;
}

.pav-color-blue-dark {
  color: #012055;
}

.pav-color-blue {
  color: #0368c1;
}

.pav-color-blue-light {
  color: #4493ff;
}

.pav-color-red {
  color: #eb6945;
}

/* SVG */
.st0 {
  fill: var(--pav-icon-svg) !important;
}

.mat-tab-label {
  border-radius: 15px !important;
  opacity: 1 !important;
  margin-bottom: 5px;
}

.mat-tab-label-active {
  /* height: 58px !important; */
}

.mat-tab-label-0-0 {
  background-color: #63c99a !important;
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-1 {
  background-color: #f7c91b !important;
  /* color: white; */
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-2 {
  background-color: #00205f !important;
  color: white !important;
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-3 {
  background-color: #006bfd !important;
  color: white !important;
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-4 {
  background-color: #eb6945 !important;
  color: white !important;
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-5 {
  background-color: #0368c1 !important;
  color: white !important;
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-6 {
  background-color: rgb(108, 214, 223) !important;
  /* color: white !important; */
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.mat-tab-label-0-7 {
  background-color: red !important;
  color: white !important;
  font-family: "Figtree-Bold" !important;
  margin-right: 5px !important;
}

.pav-btn-action {
  background: var(--pav-btn-action) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 45px !important;
  height: 45px !important;
  cursor: pointer;

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      fill: white !important;
    }
  }
}

.pav-button-icon-add .mat-form-field-underline {
  background-color: red;
  /* Cambia esto al color deseado */
}

.mat-form-field-label-wrapper .mat-form-field-label {
  font-size: 14px !important;
  color: black !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: white;
  /* Cambia esto al color deseado */
}

.pav-btn-action-table-genera-asiento {
  background: rgb(99, 201, 154) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;
  path {
    color: white !important;
    fill: white !important;
  }

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      fill: white !important;
    }
  }
}

.pav-btn-action-table-ajuste {
  background: rgb(80, 223, 235) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;
  path {
    color: white !important;
    fill: white !important;
  }

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      fill: white !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table-ajuste {
    width: 40px !important;
    height: 40px !important;
  }
}

.pav-btn-action-table-borrar-destino {
  background: rgb(247, 201, 27) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;
  path {
    color: white !important;
    fill: white !important;
  }

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      fill: white !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table-borrar-destino {
    width: 40px !important;
    height: 40px !important;
  }
}

.pav-btn-action-table-cuenta-pagar {
  background: rgb(235 148 80) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      /* fill: white !important; */
    }
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table-cuenta-pagar {
    width: 40px !important;
    height: 40px !important;
  }
}

.pav-btn-action-table-cuenta-cobrar {
  background: rgb(99, 201, 154) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      /* fill: white !important; */
    }
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table-cuenta-cobrar {
    width: 40px !important;
    height: 40px !important;
  }
}

.pav-btn-action-table-copiar-item {
  background: rgb(0, 32, 95) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;
  path {
    color: white !important;
    fill: white !important;
  }

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      fill: white !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table-copiar-item {
    width: 40px !important;
    height: 40px !important;
  }
}

.pav-btn-action-table-rendiciones-colaborador {
  background: rgb(35, 96, 255) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;

  &:hover {
    /* background: #2360FF; */

    /* path {
      color: white !important;
      fill: white !important;
    } */
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table-rendiciones-colaborador {
    width: 40px !important;
    height: 40px !important;
  }
}

.pav-btn-action-table {
  background: var(--pav-btn-action) !important;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
  align-items: center;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;
  path {
    color: white !important;
    fill: white !important;
  }

  &:hover {
    background: #0368c1;

    path {
      color: white !important;
      fill: white !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action-table {
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 960px) {
  .pav-btn-action {
    width: 35px !important;
    height: 35px !important;
    margin-bottom: 10px !important;
  }

  .pav-table-search input {
    height: 40px;
    width: 35%;
    border-radius: 30px;
    background: #f3f6fa;
    border: none;
    padding-left: 25px;
    color: #121d44;
    opacity: 0.5;
    padding-right: 50px;
    background-image: url(assets/svg/search.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px;
    background-position-x: 255px;
  }

  .pav-table-search input:focus {
    outline: none !important;
    height: 40px;
    width: 35%;
    border-radius: 30px;
    background: #f3f6fa;
    border: none;
    padding-left: 25px;
    color: #121d44;
    opacity: 0.5;
    padding-right: 50px;
    background-image: url(assets/svg/search.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px;
    background-position-x: 255px;
  }
}

.pav-panel {
  margin-top: 100px;
}

mat-ink-bar {
  background-color: #c6c4c0 !important;
  height: 6px !important;
}
